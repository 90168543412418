<template>
  <nav class="flex items-center justify-between flex-wrap bg-teal p-4" :style="navStyle">
    <div class="flex items-center flex-no-shrink text-white mr-6">
      <router-link 
        :to="{name: 'home'}"
        class="noflex">
          <div class="sm-header-title">Miam!</div>
          <div class="sm-header-subtitle">Cheffe nomade</div>
      </router-link>
    </div>
    <div class="social-networks flex-grow">
      <a href="https://www.facebook.com/Miamvercors" title="Visiter ma page Facebook" target="_blank">
        <i class="pi pi-facebook"></i>
      </a>
      <a href="https://www.instagram.com/MiamVercors/" title="Visiter ma page Instagram" target="_blank">
        <i class="pi pi-instagram"></i>
      </a>
      <a href="#" @click.stop.prevent="subscribeToNewsletter" title="Recevoir les informations par mail">
        <i class="pi pi-at"></i>
      </a>
      <a href="#" @click.stop.prevent="joinWhatsApp" title="Rejoindre le groupe WhatsApp" class="inline lg:hidden">
        <i class="pi pi-whatsapp"></i>
      </a>
    </div>
    <div class="block lg:hidden">
      <button @click="toggleMenu" class="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white">
        <svg class="h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>
    <div 
      class="w-full block lg:flex lg:items-center lg:w-auto lg:block"
      :class="{
        hidden: !menuOpened,
      }"
      @click="onMenuClicked"
      >
      <div class="sm-menu-subcontainer text-sm lg:flex-grow">
        
        <router-link 
        :to="{name: 'home'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4"
        v-tooltip="'Voir les menus du moment et passer commande'"
        >
          {{hasSeveralsMenus ? 'Menus de la semaine' : 'Menu de la semaine' }}
        </router-link>

        <router-link 
        :to="{name: 'howToOrder'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4"
        >
          Comment ça marche
        </router-link>

        <router-link 
        :to="{name: 'prestations'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4"
        >
          Prestations
        </router-link>
        
        <router-link 
        :to="{name: 'contact'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          Me contacter
        </router-link>

        <router-link 
        :to="{name: 'about'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          A propos
        </router-link>
        
        <router-link 
        v-if="isAuthenticated"
        :to="{name: 'allMenus'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          Tous les menus
        </router-link>

        <router-link 
        v-if="isAuthenticated"
        :to="{name: 'admin'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          Administration
        </router-link>

        <router-link 
        v-if="isAuthenticated"
        :to="{name: 'logout'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          Se déconnecter
        </router-link>

        <router-link 
        v-if="!isAuthenticated && alreadyLoogedOnceTimeAgo"
        :to="{name: 'login'}"
        class="block mt-4 lg:inline-block lg:mt-0 text-teal-lighter mr-4">
          Se connecter
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAdminDataStore } from '../stores/admin'
import { useFrontDataStore } from '../stores/front'

export default {
  name: "About",
  setup() {
    const adminDataStore = useAdminDataStore();
    const frontDataStore = useFrontDataStore();

    
    const subscribeToNewsletter = () => {
      frontDataStore.displayRegisterToNewsletterPopin();
    };

    const bgColor = process.env.VUE_APP_HEADER_COLOR;
    const navStyle = bgColor ? `background-color: ${bgColor};` : '';
    

    const menuOpened = ref(false);
    const toggleMenu = () => {
      menuOpened.value = !menuOpened.value;
    };

    const onMenuClicked = () => {
      menuOpened.value = false;
    };

    const { homeMenus } = storeToRefs(frontDataStore);
    const hasSeveralsMenus = computed(() => homeMenus.value.length >= 2);

    const alreadyLoogedOnceTimeAgo = !!(localStorage.getItem('loginUsername'));

    const { isAuthenticated } = storeToRefs(adminDataStore);

    const joinWhatsApp = () => {
      document.location='ht'+'tps://c'+'hat.w'+'hat'+'sapp.com/H4K5J'+'osJ29XEeTtKXiNSWa';
    }

    return {
      alreadyLoogedOnceTimeAgo,
      hasSeveralsMenus,
      isAuthenticated,
      joinWhatsApp,
      menuOpened,
      navStyle,
      onMenuClicked,
      subscribeToNewsletter,
      toggleMenu,
    };
  }
};
</script>