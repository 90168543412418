import { useFrontDataStore } from '../stores/front';
import { useAdminDataStore } from '../stores/admin';
import api from './api'
import replaceHtml from '../misc/replaceHtml';
import normalizeMenu from '../misc/normalizeMenu';
import timeoutAsync from '../misc/timeoutAsync';


function normalizePage(page) {
  return {
    ...page,
    descriptionHtml: replaceHtml(page.description),
  };
}


const get = {
  ApiBaseUrl: `${process.env.VUE_APP_APIROOTURL}`,
  
  getHomeMenus(forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || frontStore.homeMenus.length == 0)  {
      api
        .listMenus("isDefaultMenu=true")
        .then((response) => {
          const menus = response;
          const richMenus = menus
            .map(menu => normalizeMenu(menu, true))
            .sort((m1, m2) => {
              if(m1.canOrder != m2.canOrder) {
                return m1.canOrder ? -1 : 1;
              }
              if(m1.forDate == m2.forDate) {
                return 0;
              }
              return m1.forDate > m2.forDate ? 1 : -1;
            });
          frontStore.setHomeMenus(richMenus);
        });
    }
  },
  
  getMenu(id, forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || !frontStore.menu[id] || Object.keys(frontStore.menu[id]) == 0)  {
      return api
        .getMenu(id)
        .then((response) => {
          const menu = response;
          frontStore.setMenu(normalizeMenu(menu, true));
        });
    }
  },
  getAdminMenu(id, forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || !frontStore.adminMenu[id] || Object.keys(frontStore.adminMenu[id]) == 0)  {
      const isLoading = frontStore.adminMenuLoading[id];
      if(isLoading && !forceRefresh) {
        return;
      }
      frontStore.setAdminMenuLoading(id, true);
      api
        .getAdminMenu(id)
        .then((response) => {
          const menu = response;          
          frontStore.setAdminMenu(normalizeMenu(menu, false));
          frontStore.setAdminMenuLoading(id, false);
        });
    }
  },
  
  getOrder(orderId, shortCode, forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || frontStore.order.id != orderId)  {
      frontStore.setOrderIsLoading(true);
      api
        .getOrder(orderId, shortCode)
        .then((response) => {
          if(!response.error) {
            frontStore.setOrder({
              ...response,
              initialFoodMenuOrderItems: {...response.foodMenuOrderItems},
            });
          }else{
            frontStore.setOrderIsLoading(false);
            if(response.message) {
              frontStore.setOrderLoadError(response.message);
            }  
          }
        })
        .catch((e) => {
          frontStore.setOrderIsLoading(false);
          if(e.message) {
            frontStore.setOrderLoadError(e.message);
          }
        })
        ;
    }
  },
  async listMenus(forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || frontStore.menus.length == 0)  {
      frontStore.setMenusAreLoading(true);
      
      let allMenus = [];
      let page = 1;
      const itemsPerPage = 50;
      let hasMore = true;
      while(hasMore) {

        var response = await api
        .listMenus("order[id]=desc&itemsPerPage="+itemsPerPage+"&page="+page);
        //.then((response) => {
          const menus = response;
            hasMore = menus.length == itemsPerPage;

            allMenus = [
              ...allMenus, 
              ...(menus.map((menu) => (normalizeMenu(menu))))
            ];

            //we add some order to sub properties here
            const menusOrdered = allMenus
            .sort((a, b) => ((a.forDate < b.forDate) ? 1 : -1))
            ;

            frontStore.setMenus(menusOrdered);
            page++;
          //frontStore.setMenus(menusOrdered);
        ///});
          }
    }
  },
  async listAdminMenus(forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || frontStore.adminMenus.length == 0)  {
      frontStore.setAdminMenusAreLoading(true);

      let allMenus = [];
      let page = 1;
      const itemsPerPage = 50;
      let hasMore = true;
      while(hasMore) {

        const response = await api
          .listAdminMenus("order[id]=desc&itemsPerPage="+itemsPerPage+"&page="+page);
          //.then((response) => {
            const menus = response;
            hasMore = menus.length == itemsPerPage;

            allMenus = [
              ...allMenus, 
              ...(menus.map((menu) => (normalizeMenu(menu))))
            ];

            //we add some order to sub properties here
            const menusOrdered = allMenus
            .sort((a, b) => ((a.forDate < b.forDate) ? 1 : -1))
            ;

            frontStore.setAdminMenus(menusOrdered);
            page++;
          //});
      }
    }
  },
  listPages(forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || Object.keys(frontStore.pages).length == 0)  {
      frontStore.setPagesAreLoading(true);
      api
        .listPages("")
        .then((response) => {
          const pages = response;

          //we add some order to sub properties here
          const pagesOrdered = pages
          .sort((a, b) => ((a.shortname.localeCompare(b.shortname))))
          .map((page) => (normalizePage(page)))
          ;
          const pagesAsHash = pagesOrdered.reduce((acc, item) => ({
            [item.shortname]: item,
            ...acc,
          }), {});
          frontStore.setPages(pagesAsHash);
        });
    }
  },
  listMailTemplates(forceRefresh = false) {
    var frontStore = useFrontDataStore();
    if(forceRefresh || Object.keys(frontStore.mailTemplates).length == 0)  {
      frontStore.setMailTemplatesAreLoading(true);
      api
        .listMailTemplates("")
        .then((response) => {
          const mails = response;

          //we add some order to sub properties here
          const mailsOrdered = mails
          .sort((a, b) => ((a.shortname.localeCompare(b.shortname))))
          //.map((page) => (normalizePage(page)))
          ;
          const mailsAsHash = mailsOrdered.reduce((acc, item) => ({
            [item.shortname]: item,
            ...acc,
          }), {});
          frontStore.setMailTemplates(mailsAsHash);
        });
    }
  },
  listCustomers(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || Object.keys(adminStore.customers).length == 0)  {
      adminStore.setCustomersAreLoading(true);
      api
        .listCustomers()
        .then((response) => {
          const customers = response;

          //we add some order to sub properties here
          const customersOrdered = customers
          .sort((a, b) => ((a.lastName.localeCompare(b.lastName))))
          //.map((page) => (normalizePage(page)))
          ;
          
          adminStore.setCustomers(customersOrdered);
        });
    }
  },
  
  async listAllNewsletterRecipients(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.newsletterRecipients.length == 0)  {
      adminStore.setNewsletterRecipientsAreLoading(true);
      let ended = false;
      let page = 1;
      let recipients = [];
      while(!ended && page < 100) {
        const response = await api.listNewsletterRecipients(page);
        recipients = [...recipients, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      
      adminStore.setNewsletterRecipients(recipients.sort((a, b) => a.email.localeCompare(b.email)));
    }
  },
  async listAllFoodMenuClients(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodMenuClients.length == 0)  {
      adminStore.setFoodMenuClientsAreLoading(true);
      let ended = false;
      let page = 1;
      let clients = [];
      while(!ended && page < 100) {
        const response = await api.listFoodMenuClients(page);
        clients = [...clients, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      
      adminStore.setFoodMenuClients(clients.sort((a, b) => a.forEmail && b.forEmail ? a.forEmail.localeCompare(b.forEmail) : 0));
    }
  },
  
  async refreshRecentFoodIngredients(nb = 2) {
    var adminStore = useAdminDataStore();
    const itemsPerPage = nb;
    const response = await api.listFoodIngredients(1, "order[modifiedAt]=desc&itemsPerPage="+itemsPerPage);
    const members = response['hydra:member'];
    for(let i = 0; i < members.length; i++) {
      var foodIngredient = members[i];
      adminStore.refreshFoodIngredient(foodIngredient);
    }
  },
  async refreshAFoodIngredient(id) {
    var adminStore = useAdminDataStore();
    const response = await api.listFoodIngredients(1, "id="+id+"&order[modifiedAt]=desc&itemsPerPage=1");
    const members = response['hydra:member'];
    for(let i = 0; i < members.length; i++) {
      var foodIngredient = members[i];
      adminStore.refreshFoodIngredient(foodIngredient);
    }
  },
  async refreshAllFoodIngredients() {
    return this.listAllFoodIngredients(true, false);
  },
  async listAllFoodIngredients(forceRefresh = false, setLoading = true) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodIngredients.length == 0)  {
      if(adminStore.foodIngredientsAreLoading) {
        while(adminStore.foodIngredientsAreLoading) {
          await timeoutAsync(200);
        }
        return;
      }
      if(setLoading) {
        adminStore.setFoodIngredientsAreLoading(true);
      }
      let page = 1;
      const itemsPerPage = 50;
      let ingredients = [];
      const response = await api.listFoodIngredients(page, "itemsPerPage="+itemsPerPage);
      


      const nbItems = response['hydra:totalItems'];
      const members = response['hydra:member'];
      ingredients = [...ingredients, ...(members)];
      const nbItemsPerPage = itemsPerPage;
      if(nbItems > nbItemsPerPage) {
        const nbPage = Math.ceil(nbItems / nbItemsPerPage);
        let items = [];
        for(let page = 2; page <= nbPage; page++) {
          let promise = api.listFoodIngredients(page, "itemsPerPage="+itemsPerPage);
          items[page] = (promise);
        }
        for(let page = 2; page <= nbPage; page++) {
          const itemReponse = await items[page];
          const localItems = itemReponse['hydra:member'];
          if(localItems) {
            ingredients = [...ingredients, ...(localItems)];
          }
        }
      }

      ingredients = ingredients.map(i => {
        let total = 0;
        if(i.allGluten) {
          total++;
        }
        if(i.allCrustrace) {
          total++;
        }
        if(i.allOeuf) {
          total++;
        }
        if(i.allArachide) {
          total++;
        }
        if(i.allPoisson) {
          total++;
        }
        if(i.allSoja) {
          total++;
        }
        if(i.allLait) {
          total++;
        }
        if(i.allFruitCoques) {
          total++;
        }
        if(i.allCeleri) {
          total++;
        }
        if(i.allMoutarde) {
          total++;
        }
        if(i.allSesame) {
          total++;
        }
        if(i.allSulfite) {
          total++;
        }
        if(i.allLupin) {
          total++;
        }
        
        return {
          ...i, 
          allCount: total
        };

      });
      
      adminStore.setFoodIngredients(ingredients.sort((a, b) => a.name.localeCompare(b.name)));
    }
  },
  
  async listAllFoodMenuItems(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodMenuItems.length == 0)  {
      if(adminStore.foodMenuItemsAreLoading) {
        return;
      }
      adminStore.setFoodMenuItemsAreLoading(true);
      let ended = false;
      let page = 1;
      let foodMenuItems = [];
      while(!ended && page < 100) {
        const response = await api.listFoodMenuItems(page);
        foodMenuItems = [...foodMenuItems, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      
      adminStore.setFoodMenuItems(foodMenuItems.sort((a, b) => a.label.localeCompare(b.label)));
    }
  },

  
  async listAllFoodMenuOrders(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodMenuOrders.length == 0)  {
      if(adminStore.foodMenuOrdersAreLoading) {
        return;
      }
      adminStore.setFoodMenuOrdersAreLoading(true);
      let ended = false;
      let page = 1;
      let foodMenuOrders = [];
      while(!ended && page < 100) {
        const response = await api.listFoodMenuOrders(page);
        foodMenuOrders = [...foodMenuOrders, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      adminStore.setFoodMenuOrders(foodMenuOrders);
    }
  },

  async listAllOrderDiscountRules(forceRefresh = false) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.orderDiscountRules.length == 0)  {
      if(adminStore.orderDiscountRulesAreLoading) {
        return;
      }
      adminStore.setOrderDiscountRulesAreLoading(true);
      let ended = false;
      let page = 1;
      let discounts = [];
      while(!ended && page < 100) {
        const response = await api.listOrderDiscountRules(page);
        discounts = [...discounts, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      
      adminStore.setOrderDiscountRules(discounts.sort((a, b) => `${a.forEmail}`.localeCompare(`${b.forEmail}`)));
    }
  },
  async refreshRecentFoodRecipes(nb = 2) {
    //return this.listAllFoodRecipes(true, false);
    var adminStore = useAdminDataStore();
    const itemsPerPage = nb;
    const response = await api.listFoodRecipes(1, "order[modifiedAt]=desc&itemsPerPage="+itemsPerPage);
    let members = response['hydra:member'];
    members = members.map((recipe)=> {
      return this.fillFoodRecipeIngredient(recipe, adminStore);
    });
    for(let i = 0; i < members.length; i++) {
      var foodRecipe = members[i];
      adminStore.refreshFoodRecipe(foodRecipe);
    }
  },
  async refreshAFoodRecipe(id) {
    //return this.listAllFoodRecipes(true, false);
    var adminStore = useAdminDataStore();
    const response = await api.listFoodRecipes(1, "id="+id+"&order[modifiedAt]=desc&itemsPerPage=1");
    const members = response['hydra:member'];
    for(let i = 0; i < members.length; i++) {
      let foodRecipe = members[i];
      foodRecipe = this.fillFoodRecipeIngredient(foodRecipe, adminStore);
      adminStore.refreshFoodRecipe(foodRecipe);
    }
  },
  refreshAllFoodRecipes() {
    return this.listAllFoodRecipes(true, false);
  },
  async listAllFoodRecipes(forceRefresh = false, setLoading = true) {
    return await this.listAllFoodRecipesInSameTime(forceRefresh, setLoading);
    /*
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodRecipes.length == 0)  {
      if(adminStore.foodRecipesAreLoading) {
        return;
      }
      if(setLoading) {
        adminStore.setFoodRecipesAreLoading(true);
      }
      let ended = false;
      let page = 1;
      const itemsPerPage = 50;
      let recipes = [];
      while(!ended && page < 100) {
        const response = await api.listFoodRecipes(page, "itemsPerPage="+itemsPerPage);
        recipes = [...recipes, ...(response['hydra:member'])];
        ended = !(response['hydra:view'] && response['hydra:view']['hydra:next']);
        page++;
      }
      
      adminStore.setFoodRecipes(recipes.sort((a, b) => a.name.localeCompare(b.name)));
    }
    */
  },
  async listAllFoodRecipesInSameTime(forceRefresh = false, setLoading = true) {
    var adminStore = useAdminDataStore();
    if(forceRefresh || adminStore.foodRecipes.length == 0)  {
      if(adminStore.foodRecipesAreLoading) {
        return;
      }
      if(setLoading) {
        adminStore.setFoodRecipesAreLoading(true);
      }
      let page = 1;
      const itemsPerPage = 80;
      let recipes = [];
      const response = await api.listFoodRecipes(page, "itemsPerPage="+itemsPerPage);
      const nbItems = response['hydra:totalItems'];
      const members = response['hydra:member'];
      recipes = [...recipes, ...(members)];
      const nbItemsPerPage = itemsPerPage;
      if(nbItems > nbItemsPerPage) {
        const nbPage = Math.ceil(nbItems / nbItemsPerPage);
        let items = [];
        for(let page = 2; page <= nbPage; page++) {
          let promise = api.listFoodRecipes(page, "itemsPerPage="+itemsPerPage);
          items[page] = (promise);
        }
        for(let page = 2; page <= nbPage; page++) {
          const itemReponse = await items[page];
          const localItems = itemReponse['hydra:member'];
          if(localItems) {
            recipes = [...recipes, ...(localItems)];
          }
        }
      }
      await this.listAllFoodIngredients();

      
      recipes = recipes.map((recipe)=> {
        return this.fillFoodRecipeIngredient(recipe, adminStore);
      });

      adminStore.setFoodRecipes(recipes.sort((a, b) => a.name.localeCompare(b.name)));
    }
  },
  fillFoodRecipeIngredient(recipe, adminStore) {
    recipe.foodRecipeIngredients = recipe.foodRecipeIngredients.map((fri) => {
      if(typeof fri.foodIngredient == 'string') {
        var ingredient = adminStore.foodIngredients.find(fi => fi['@id'] == fri.foodIngredient);
        fri.foodIngredient = ingredient;
      }
      return fri;
    });
    return recipe;
  },
  
  async getFoodRecipe(id, forceRefresh = false) {
    var adminStore = useAdminDataStore();
    let e = adminStore.foodRecipes.find(r => r.id == id);
    e = this.fillFoodRecipeIngredient(e, adminStore);
    var isLoaded = e ? (typeof e.foodMenuItemOptions === 'object') : false;
    var isLoading = e ? e.isLoading : false;
    if((!isLoaded || forceRefresh) && !isLoading) {
      // prevent double loading
      if(e) {
        e.isLoading = true;
        adminStore.setFoodRecipe(id, e);
      }

      let response = await api.getFoodRecipe(id);
      response = this.fillFoodRecipeIngredient(response, adminStore);
      response.isLoading = false;
      adminStore.setFoodRecipe(id, response);
      return response;
    }
    return e;
  },
};


export default get;